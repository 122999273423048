import { unescape } from 'lodash';

import FontIcon from 'components/icons';

import './styles/two-by-two.scss';

type TwoByTwoProps = {
  activeFeatureIndex: number,
  features: any[],
  setActiveFeatureIndex: (number) => void
}

type ItemProps = {
  active: boolean
  altText: string,
  contentList?:any,
  description: string,
  icon?: string,
  iconMarkup: string,
  iconMaxWidth: string,
  iconSprite: boolean,
  imageUrl: string,
  index: number,
  onClickHandler: () => void,
  title: string,
  url: any
}

const TwoByTwo = ({ features, activeFeatureIndex, setActiveFeatureIndex }:TwoByTwoProps) => {
  const handleItemClick = i => {
    setActiveFeatureIndex(i);
  };

  return (
    <div className="two-by-two">
      <div className="two-by-two-row">
        {features.map(
          (
            {
              title,
              body,
              iconMarkup,
              iconMaxWidth,
              mobileScreenshot,
              url,
              icon,
              icon_from_library,
              content_list,
              icon_width,
              mobile_screenshot,
              alt_text,
            },
            i
          ) => {
            if (i > 1) {
              // First two items
              return null;
            }

            return (
              <Item
                key={ title }
                title={ title }
                description={ body }
                iconSprite={ icon_from_library || undefined }
                contentList={ content_list || undefined }
                iconMarkup={ iconMarkup || icon }
                iconMaxWidth={ iconMaxWidth || icon_width }
                active={ activeFeatureIndex === i }
                onClickHandler={ () => handleItemClick(i) }
                url={ url }
                imageUrl={ mobileScreenshot || mobile_screenshot.source_url }
                index={ i }
                altText={
                  typeof mobileScreenshot === 'undefined'
                    ? alt_text
                    : mobileScreenshot.alt_text
                }
              />
            );
          }
        )}
      </div>
      <div className="two-by-two-row">
        {features.map(
          (
            {
              title,
              body,
              iconMarkup,
              iconMaxWidth,
              content_list,
              mobileScreenshot,
              url,
              icon,
              icon_from_library,
              icon_width,
              mobile_screenshot,
              alt_text,
            },
            i
          ) => {
            if (i < 2) {
              // Second two items
              return null;
            }

            return (
              <Item
                key={ title }
                title={ title }
                description={ body }
                iconSprite={ icon_from_library || undefined }
                contentList={ content_list || undefined }
                iconMarkup={ iconMarkup || unescape(icon) }
                iconMaxWidth={ iconMaxWidth || icon_width }
                active={ activeFeatureIndex === i }
                onClickHandler={ () => handleItemClick(i) }
                url={ url }
                imageUrl={ mobileScreenshot || mobile_screenshot.source_url }
                index={ i }
                altText={
                  typeof mobileScreenshot === 'undefined'
                    ? alt_text
                    : mobileScreenshot.alt_text
                }
              />
            );
          }
        )}
      </div>
    </div>
  );
};

function Item({
  active,
  onClickHandler,
  iconSprite,
  iconMarkup,
  iconMaxWidth,
  contentList,
  icon,
  title,
  description,
  imageUrl,
  altText,
}:ItemProps) {

  return (
    <div className="item-container">
      <button className={ `item-button ${active ? 'active' : ''}` } onClick={ onClickHandler } type="button">
        <div className="btn-focus" tabIndex={ -1 }>
          <header className="item-header">          
            <div className="item-icon"
              dangerouslySetInnerHTML={ iconSprite ? undefined : { __html: iconMarkup || icon } as any }
              style={ { 'maxWidth': iconMaxWidth } }
            >
              {iconSprite && <FontIcon icon={ iconSprite } /> }
            </div>
            <h3 className="item-title"> {title} <FontIcon icon="chevron-right" /> </h3>
          </header>
          {contentList && (
            <div className="content-list">
              <h4 className="">{contentList.title}</h4><div className="wysiwyg-section" dangerouslySetInnerHTML={ { __html: contentList.list_items } } />
            </div>
          
          )}
          <p className="item-copy"> {description} </p>
        </div>
      </button>
      <div className="bubble-container">
        <div className="bubble">
          <img
            loading="lazy"
            height="134"
            src={ imageUrl }
            alt={ altText || 'app screenshot' }
          />
        </div>
        <div className="bubble-border" />
      </div>
    </div>
  );
}

export default TwoByTwo;
